<!--
 * @Author: 569353634@qq.com
 * @Date: 2022-12-03 14:38:50
 * @LastEditors: 569353634@qq.com
 * @LastEditTime: 2022-12-24 17:57:06
-->
<template>
  <body-layer>
    <div class="base">
      <div>
        <div class="code"><img src="@/assets/images/wxcode.png" alt=""></div>
        <div class="title">微信扫一扫进入小程序</div>
      </div>
      <div>
        <div class="code"><img src="@/assets/images/down-app.jpg" alt=""></div>
        <div class="title">扫码下载金小央APP</div>
      </div>
    </div>
  </body-layer>
</template>

<script>
import bodyLayer from '@/components/bodyLayer.vue';
export default {
  components: { bodyLayer }
}
</script>

<style lang="scss" scoped>
.base {
  width: 100%;
  background-color: #fff;
  padding: 140px 230px;
  position: relative;
  display: flex;
  justify-content: space-between;

  .code {
    width: 280px;
    height: 280px;
    text-align: center;
    line-height: 280px;
    background-color: #E3E3E3;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    text-align: center;
    padding-top: 30px;
    font-size: 18px;
  }
}
</style>